import React, { useEffect, useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import moment from 'moment'
import { CommonService } from '../_services/common.service'
import * as Sentry from '@sentry/react'
import './editorStyles.css'
import './styles/coach-note-view.css'
import Popup from 'reactjs-popup'
import SamaDropdownBox from '../_components/SamaDropdownBox'

const CoachNoteView = ({
    mainTitle,
    close,
    open,
    keyIndex,
    item,
    subTitle,
    coacheeId,
    readonly = false,
    goBack,
    onSave,
}: {
    mainTitle: string
    close: any
    open: any
    keyIndex: string
    item: any
    subTitle: string
    coacheeId: string
    readonly?: boolean
    goBack: any
    onSave: any
}) => {
    const noteEditorRef = useRef()
    const workChallengeEditorRef = useRef(null)
    const personalChallengeEditorRef = useRef(null)
    const [hasBeenSaved, setSaved] = useState(false)
    const [cannotSave, setCannotSave] = useState(false)
    const [dataChanged, setDataChanged] = useState(false)
    const [showCloseWarning, setShowCloseWarning] = useState(false)
    const [disableSave, setDisableSave] = useState(false)
    const [initialCompetency, setInitialCompetency] = useState<any>()
    const [competencyNames, setCompetencyNames] = useState<string[]>([])
    const statics = localStorage.getItem('statics')
    const competencies: Statics['competencies'] = statics
        ? JSON.parse(statics).competencies
        : {}

    useEffect(() => {
        let compValues: any = []
        let compNames = []
        for (const [key, value] of Object.entries(competencies)) {
            compNames.push(value.name)
            compValues[value.key] = value.name
        }
        setCompetencyNames(compNames)
        setInitialCompetency(compValues[item?.coachingArea])
    }, [item])

    const editorSettings = {
        menubar: false,
        inline: true,
        plugins: ['insertdatetime', 'lists'],
        paste_as_text: true,
        color_cols: 6,
        color_map: [
            '#FF2600',
            'Red',
            '#FFFB00',
            'Yellow',
            '#00F900',
            'Green',
            '#FF40FF',
            'Purple',
            '#00FDFF',
            'Blue',
        ],
        custom_colors: false,
        toolbar: 'bold italic backcolor bullist numlist ',
        content_style: `
            ul, ol { padding-left: 15px; }
        `,
    }

    const canClose = (forceClose = false) => {
        if (forceClose === false && dataChanged) {
            setShowCloseWarning(true)
        } else {
            setShowCloseWarning(false)
            close(false)
        }
    }

    const insertDate = () => {
        if (noteEditorRef.current) {
            // @ts-ignore
            let content = noteEditorRef.current.getContent()
            const dateString = moment(new Date()).format('DD MMMM YYYY, HH:mm')
            content = dateString + content
            // @ts-ignore
            noteEditorRef.current.setContent(content)
        }
    }

    const someThingChanged = () => {
        setDataChanged(true)
    }

    const saveNote = () => {
        if (
            noteEditorRef.current &&
            workChallengeEditorRef.current &&
            personalChallengeEditorRef.current
        ) {
            setDisableSave(true)

            let compKey = null
            for (const [key, value] of Object.entries(competencies)) {
                if (value.name === initialCompetency) {
                    compKey = value.key
                }
            }

            let object: {
                _id: string
                main: any
                keyChallenge: any
                personalChallenge: any
                coachingArea: string | null
                completed?: boolean
            } = {
                _id: item._id,
                // @ts-ignore
                main: noteEditorRef.current.getContent(),
                // @ts-ignore
                keyChallenge: workChallengeEditorRef.current.getContent(),
                personalChallenge:
                    // @ts-ignore
                    personalChallengeEditorRef.current.getContent(),
                coachingArea: compKey,
            }

            if (item.booking) {
                object.completed = true
            }

            if (
                object.main === '' &&
                object.keyChallenge === '' &&
                object.personalChallenge === ''
            ) {
                setCannotSave(true)
                setDisableSave(false)
                setTimeout(
                    function () {
                        setCannotSave(false)
                    }.bind(this),
                    3000,
                )
            } else {
                // empty string gets removed form the put request
                // so have to send something back in order to clear
                // the data
                if (object.personalChallenge === '') {
                    object.personalChallenge = '<p></p>'
                }
                if (object.keyChallenge === '') {
                    object.keyChallenge = '<p></p>'
                }
                CommonService.saveNotes(coacheeId, object)
                    .then(() => {
                        onSave()
                        setDataChanged(false)
                        setSaved(true)
                        setTimeout(
                            function () {
                                setDisableSave(false)
                                setSaved(false)
                            }.bind(this),
                            3000,
                        )
                    })
                    .catch((e) => {
                        alert(
                            'Sorry! Saving the note failed. If this problem persists please contact support@sama.io',
                        )
                        Sentry.captureException(e)
                    })
            }
        }
    }

    return (
        <>
            {open && (
                <div key={keyIndex} className="goal-view-container">
                    <div className="goal-view-box" style={{ padding: '55px' }}>
                        {readonly && (
                            <div
                                style={{
                                    marginBottom: '30px',
                                }}
                                className="btn-back"
                                onClick={goBack}
                            >
                                Back
                            </div>
                        )}
                        <div
                            className="btn-close-solid"
                            onClick={() => canClose(false)}
                        ></div>
                        <div>
                            <div className="coach-question-box">
                                <h2>{mainTitle}</h2>
                                <div className="areaOfCoaching">
                                    <p>Area of coaching</p>
                                    <SamaDropdownBox
                                        options={competencyNames}
                                        value={initialCompetency}
                                        initialValue={initialCompetency}
                                        emptyText="Select area of coaching"
                                        onChange={(value: string) => {
                                            setInitialCompetency(value)
                                            someThingChanged()
                                        }}
                                    />
                                </div>
                                <h3 style={{ display: 'inline' }}>
                                    {subTitle}
                                </h3>
                                {!readonly && (
                                    <div
                                        style={{
                                            float: 'right',
                                        }}
                                        onClick={() => insertDate()}
                                        className="pink-right-text"
                                    >
                                        Insert today's date
                                    </div>
                                )}
                                <Editor
                                    tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
                                    onKeyDown={someThingChanged}
                                    disabled={readonly}
                                    apiKey={
                                        import.meta.env
                                            .VITE_APP_TINY_MCE_API_KEY
                                    }
                                    onInit={(evt, editor) =>
                                        // @ts-ignore
                                        (noteEditorRef.current = editor)
                                    }
                                    initialValue={item.main}
                                    init={editorSettings}
                                />
                            </div>
                            <div className="coach-question-box">
                                <h3>Key challenges at work</h3>
                                <Editor
                                    tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
                                    onKeyDown={someThingChanged}
                                    disabled={readonly}
                                    apiKey={
                                        import.meta.env
                                            .VITE_APP_TINY_MCE_API_KEY
                                    }
                                    onInit={(evt, editor) =>
                                        // @ts-ignore
                                        (workChallengeEditorRef.current =
                                            editor)
                                    }
                                    initialValue={item.keyChallenge}
                                    init={editorSettings}
                                />
                            </div>
                            {/* <button onClick={log}>Log editor content</button> */}
                            <div className="coach-question-box">
                                <h3>Key personal challenges</h3>
                                <Editor
                                    tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
                                    onKeyDown={someThingChanged}
                                    disabled={readonly}
                                    apiKey={
                                        import.meta.env
                                            .VITE_APP_TINY_MCE_API_KEY
                                    }
                                    onInit={(evt, editor) =>
                                        // @ts-ignore
                                        (personalChallengeEditorRef.current =
                                            editor)
                                    }
                                    initialValue={item.personalChallenge}
                                    init={editorSettings}
                                />
                            </div>
                            {/* <button onClick={log}>Log editor content</button> */}
                        </div>
                        {!readonly && (
                            <button
                                disabled={!dataChanged || disableSave}
                                onClick={saveNote}
                                className={
                                    !dataChanged || disableSave
                                        ? 'orangeButton disabled'
                                        : 'orangeButton'
                                }
                            >
                                Save note
                            </button>
                        )}
                        {hasBeenSaved && (
                            <div className="data-saved-box">
                                <span>Note saved!</span>
                            </div>
                        )}
                        {cannotSave && (
                            <div className="warning-box">
                                <span>Can not save an empty note</span>
                            </div>
                        )}
                        <Popup
                            open={showCloseWarning}
                            position="center center"
                            modal
                            nested
                            className="alertBox"
                        >
                            {
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <h3>Unsaved changes</h3>
                                    <div>
                                        Are you sure you wish to discard your
                                        changes? Your edits will be lost.
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'inline-flex',
                                        }}
                                    >
                                        <button
                                            className="orangeButton greeny-blue small"
                                            type="button"
                                            onClick={() =>
                                                setShowCloseWarning(false)
                                            }
                                        >
                                            Cancel
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button
                                            className="orangeButton small"
                                            type="button"
                                            onClick={() => canClose(true)}
                                        >
                                            Discard
                                        </button>
                                    </div>
                                </div>
                            }
                        </Popup>
                    </div>
                </div>
            )}
        </>
    )
}

export default CoachNoteView
